<template>
  <ciam-page :title-suffix="$t('pageTitles.organizations')">
    <ciam-card>
      <ciam-card-content-line type="full-width" v-if="problem">
        <template v-slot:content>
          <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
        </template>
      </ciam-card-content-line>
      <ciam-card-header>
        <template v-slot:header>
          <ciam-clipboard title="Copy organization Id" :payload="organizationId">
            {{ organizationName }}
          </ciam-clipboard>
        </template>
        <template v-slot:subtitle
          >{{ $tc('organization.details.memberCount', organizationMemberCount, { count: organizationMemberCount }) }}
        </template>
        <template v-slot:actions>
          <ciam-button class="secondary" v-if="billingAccount" @click="goToBillingDetails()"
            >{{ $t('organization.details.billingDetails') }}
          </ciam-button>
          <ciam-button class="secondary" @click="editOrganization()">{{ $t('actions.edit') }}</ciam-button>
          <ciam-button v-if="isAdmin" class="danger" @click="contactOrganization()">
            {{ $t('organization.details.contact.button') }}
            <template v-slot:right>
              <ciam-icon name="fa-envelope"></ciam-icon>
            </template>
          </ciam-button>
        </template>
      </ciam-card-header>
      <ciam-card-content-lines v-if="isAdmin">
        <ciam-card-content-line>
          <template v-slot:label>{{ $t('organization.multipleFactors.status') }}</template>
          <template v-slot:content>
            <ciam-text v-if="organizationPolicies && organizationPolicies.mfaEnforced">MFA Configured</ciam-text>
            <ciam-text v-else>Not Configured</ciam-text>
          </template>
        </ciam-card-content-line>
      </ciam-card-content-lines>
      
    </ciam-card>
    <ciam-card class="mt-6">
      <ciam-card-content>
        <b-table
          style="clear: both"
          :striped="true"
          :hoverable="true"
          :data="members"
          :total="totalElements"
          :per-page="size"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          :row-class="(row) => (row.highlighted ? 'highlight' : '') + 'member--row'"
          backend-sorting
          backend-pagination
          :aria-next-label="$t('nextPage')"
          :aria-previous-label="$t('previousPage')"
          :aria-page-label="$t('page')"
          :aria-current-label="$t('currentPage')"
          @page-change="onPageChange"
        >
          <b-table-column field="firstName" :label="$t('organization.details.members.firstName')" v-slot="props">
            <ciam-text>{{ props.row.firstName }}</ciam-text>
          </b-table-column>

          <b-table-column field="lastName" :label="$t('organization.details.members.lastName')" v-slot="props">
            <ciam-text>{{ props.row.lastName }}</ciam-text>
          </b-table-column>

          <b-table-column field="email" :label="$t('organization.details.members.email')" v-slot="props">
            <ciam-text>{{ props.row.email }}</ciam-text>
          </b-table-column>

          <b-table-column field="role" :label="$t('organization.details.members.role')" v-slot="props">
            <ciam-text class="tag is-primary" v-if="props.row.role === 'ORG_OWNER'">
              {{ $t(`roles.values.${props.row.role}.name`) }}
            </ciam-text>
            <ciam-text class="tag" v-else>
              {{ $t(`roles.values.${props.row.role}.name`) }}
            </ciam-text>

            <ciam-text class="tag you" v-if="isItYou(props.row.id)">
              {{ $t('organization.members.you') }}
            </ciam-text>
          </b-table-column>

          <b-table-column v-slot="props">
            <template>
              <div style="display: flex; justify-content: flex-end">
                <ciam-button v-if="canIRevoke(props.row)" class="danger" @click="revokeMember(props.row)">
                  <ciam-icon name="fa-trash"></ciam-icon>
                </ciam-button>
              </div>
            </template>
          </b-table-column>

          <template #empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>{{ $t('organization.details.members.emptyList') }}</p>
              </div>
            </section>
          </template>
        </b-table>
      </ciam-card-content>
      <ciam-card-footer>
        <ciam-button class="primary" @click="inviteMember()" v-if="amIAnOrganizationOwner">
          <ciam-icon name="fa-plus"></ciam-icon> &nbsp;
          {{ $t('organization.details.members.invite') }}
        </ciam-button>
      </ciam-card-footer>
    </ciam-card>
    <ciam-card class="mt-6">
      <ciam-card-content>
        <b-table
          style="clear: both"
          :striped="true"
          :hoverable="true"
          :data="serviceAccounts"
          :total="totalElements"
          :per-page="size"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          :row-class="(row) => (row.highlighted ? 'highlight' : '') + 'member--row'"
          backend-sorting
          backend-pagination
          :aria-next-label="$t('nextPage')"
          :aria-previous-label="$t('previousPage')"
          :aria-page-label="$t('page')"
          :aria-current-label="$t('currentPage')"
          @page-change="onPageChange"
        >
          <b-table-column field="name" :label="$t('organization.details.serviceAccounts.name')" v-slot="props">
            <ciam-text>
              {{
                props.row.email.startsWith('sa_') ?
                  props.row.email.split('.')[0] :
                  props.row.email
              }}
            </ciam-text>
          </b-table-column>

          <b-table-column field="role" :label="$t('organization.details.members.role')" v-slot="props">
            <ciam-text class="tag is-primary" v-if="props.row.role === 'ORG_OWNER'">
              {{ $t(`roles.values.${props.row.role}.name`) }}
            </ciam-text>
            <ciam-text class="tag" v-else>
              {{ $t(`roles.values.${props.row.role}.name`) }}
            </ciam-text>
          </b-table-column>

          <b-table-column v-slot="props">
            <template>
              <div style="display: flex; justify-content: flex-end">
                <ciam-button v-if="canIRevoke(props.row)" class="danger" @click="revokeMember(props.row)">
                  <ciam-icon name="fa-trash"></ciam-icon>
                </ciam-button>
              </div>
            </template>
          </b-table-column>

          <template #empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>{{ $t('organization.details.members.emptyList') }}</p>
              </div>
            </section>
          </template>
        </b-table>
      </ciam-card-content>
      <ciam-card-footer>
        <ciam-alert
          v-if="!isValid"
          :title="$t('organization.details.serviceAccounts.titleAlert')"
          :description="$t('organization.details.serviceAccounts.limitDescription')"
          :type="AlertStatus.WARNING"
        >
        </ciam-alert>
        <ciam-button class="primary" :disabled="!isValid" @click="createServiceAccount()" v-if="amIAnOrganizationOwner">
          <ciam-icon name="fa-plus"></ciam-icon> &nbsp;
          {{ $t('organization.details.serviceAccounts.create') }}
        </ciam-button>
      </ciam-card-footer>
    </ciam-card>
  </ciam-page>
</template>

<script>
import Maybe from 'data.maybe-fgribreau';
import DeploymentService from '@/pages/deployments/DeploymentService';
import { pathOr } from 'ramda';
import router, { routeNames } from '@/router';
import OrganizationService from '@/pages/organizations/OrganizationService';
import { intOr } from '@/util/fp';
import { Notification } from 'vue-notifyjs';
import CiamAlert, {AlertStatus} from '@/components/CiamAlert'
import i18n from '@/i18n';
import Vue from 'vue';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CommonService from '@/pages/commonService';
import createServiceAccount from '@/pages/organizations/CreateServiceAccount.vue';

export default {
  name: 'OrganizationDetails',
  components: { CiamClipboard, CiamAlert },
  data() {
    return {
      AlertStatus: AlertStatus,
      canDeleteMembers: false,
      amIAnAdmin: this.$keycloak.hasRealmRole('ADMIN'),
      organization: Maybe.Nothing(),
      organizationPolicies: null,
      members: [],
      serviceAccounts: [],
      service: new DeploymentService(),
      data: [],
      name: this.$route.query.name || '',
      totalElements: 0,
      totalPages: 0,
      sortField: this.$route.query.sortField || 'name',
      sortOrder: this.$route.query.sortOrder || 'asc',
      defaultSortOrder: 'asc',
      page: intOr(1, this.$route.query.page),
      size: intOr(15, this.$route.query.size),
      problem: null,
    };
  },
  methods: {

    inviteMember() {
      this.$router
        .push({
          name: routeNames.InviteMember,
          params: { id: this.organizationId },
        })
        .catch(() => {});
    },
    createServiceAccount() {
      this.$router
        .push({
          name: routeNames.CreateServiceAccount,
          params: { id: this.organizationId },
        })
        .catch(() => {});
    },
    goToBillingDetails() {
      OrganizationService.getBillingDetailsUrl(this.organizationId).then((url) => (location.href = url));
    },
    contactOrganization() {
      const subject = prompt(i18n.t('organization.details.contact.prompt'), '[Cloud-IAM] ');
      if (subject === null) {
        return;
      }

      OrganizationService.contactOrganization(this.organizationId, subject).then((url) =>
        window.open(url, '_blank').focus()
      );
    },
    editOrganization() {
      this.$router
        .push({
          name: routeNames.OrganizationEdit,
          params: { id: this.organizationId },
        })
        .catch(() => {});
    },
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },

    /**
     *
     * @return {boolean}
     */
    canIRevoke(member) {
      return (
        (member.role === 'ORG_OWNER' && this.canDeleteOwners) || (member.role !== 'ORG_OWNER' && this.canDeleteMembers)
      );
    },
    revokeMember(user) {
      if (!confirm(i18n.t('organization.details.members.confirmRemoval', { email: user.email }))) {
        return;
      }
      OrganizationService.revokeMember(this.organizationId, user.id)
        .then(() =>
          Notification.notify({
            message: i18n.t('notifications.memberHasBeenRevoked', { email: user.email }),
            type: 'info',
          })
        )
        .catch((problem) =>
          Notification.notify({
            message: problem.detail,
            type: 'warning',
          })
        )
        .finally(() => this.refreshList());
    },

    refreshList() {
      const loader = this.$loading.show();
      Promise.all([
        OrganizationService.get(this.organizationId),
        OrganizationService.getSecurityPolicy(this.organizationId),
        OrganizationService.getMembers(this.organizationId),
        this.$keycloak.loadUserInfo(),
      ])
        .then(([organization, policies, members, user]) => {
          this.organization = Maybe.Just(organization);
          this.organizationPolicies = policies;
          // Filter members where serviceAccount equal false
          this.members = members.filter(member => !member.serviceAccount);
          this.serviceAccounts = members.filter(member => member.serviceAccount);
          this.currentUser = user;
          const isSomehowAnOrganizationAdministrator = this.amIAnAdmin || this.amIAnOrganizationOwner;
          this.canDeleteOwners = isSomehowAnOrganizationAdministrator && this.organizationOwnerCount > 1;
          this.canDeleteMembers = isSomehowAnOrganizationAdministrator;
        })
        .catch((err) => {
          if (pathOr(-1, 'response.status'.split('.'), err) === 404) {
            return;
          }
          this.problem = err
          this.$log.error("Error while fetching the organization informations", err);
          this.organization = Maybe.Nothing();
          this.members = [];
          this.serviceAccounts = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
    isItYou(userId) {
      return this.currentUser.id === userId;
    },
  },

  mounted: function () {
    // ensure that the params.organization_id & query.organization_id are the same
    // rational: if they are not, it means the user changed organization while STILL being on
    // the organization detail page in that case it's bad ux, so we change the current displayed organization for him
    if (this.$route.query.organization_id !== this.$route.params.id) {
      this.$router
        .push({
          ...this.$route,
          params: {
            id: this.$route.query.organization_id,
          },
        })
        .catch(() => {});
    }

    this.refreshList();
  },
  computed: {
    isValid() {
      return this.serviceAccounts.length < 4;
    },
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
    /**
     * @return {boolean} true if the user is an organization owner
     */
    amIAnOrganizationOwner() {
      return this.amIAnAdmin || this.members.some((candidate) => candidate.id === this.currentUser.id);
    },
    organizationId() {
      return this.$route.params.id;
    },
    organizationName() {
      return this.organization.map((d) => d.name).getOrElse('');
    },

    billingAccount() {
      return this.organization.map((o) => o._links['billing-account']).getOrElse(null);
    },
    organizationMemberCount() {
      return this.members.length;
    },
    organizationOwnerCount() {
      return this.members.filter((candidate) => candidate.role === 'ORG_OWNER').length;
    },
  },
};
</script>

<style lang="scss">
tr.member--row td {
  height: 65px;
  vertical-align: middle;
}

.you {
  margin-left: 0.2rem;
}
</style>
